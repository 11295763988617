import { Card, Input } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CardContent, FormLabel, Stack, useTheme } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLocalStorage } from "@src/appV2/lib/utils";
import { useSearchParams } from "@src/appV2/lib/utils/useSearchParams";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { LoadingButton } from "../../components/LoadingButton";
import { useCreateReferral } from "../api/useCreateReferral";
import { referralsUrl } from "../api/useReferrals";

const redeemFormSchema = z.object({
  referralCode: z.string({ message: "Referral code is required" }),
});
type ReferralForm = z.infer<typeof redeemFormSchema>;

interface RedeemReferralCodeProps {
  placementId: string;
}

export function RedeemReferralCode(props: RedeemReferralCodeProps) {
  const { placementId } = props;
  const queryClient = useQueryClient();
  const theme = useTheme();
  const worker = useDefinedWorker();
  const searchParams = useSearchParams();
  const [referralCode, setReferralCode] = useLocalStorage<string | undefined>(
    "PLACEMENT_REFERRAL_CODE",
    typeof searchParams.referralCode === "string" ? searchParams.referralCode : undefined
  );
  const { mutateAsync: createReferral, isLoading: isCreateReferralLoading } = useCreateReferral({
    onSuccess: async () => {
      await queryClient.invalidateQueries([referralsUrl]);
    },
  });
  const { showSuccessToast, showErrorToast } = useToast();

  const redeemForm = useForm<ReferralForm>({
    resolver: zodResolver(redeemFormSchema),
    mode: "onSubmit",
    defaultValues: {
      referralCode,
    },
  });

  const { handleSubmit, control, setValue } = redeemForm;

  useEffect(() => {
    if (typeof searchParams.referralCode === "string") {
      setReferralCode(searchParams.referralCode);
      setValue("referralCode", searchParams.referralCode);
    }
  }, [searchParams.referralCode, setReferralCode, setValue]);

  return (
    <Card
      sx={{
        p: 2,
        background: theme.palette.background.tertiary,
      }}
    >
      <CardContent component={Stack} spacing={7}>
        <Text semibold variant="h6">
          Redeem referral code
        </Text>
        <Stack
          spacing={2}
          component="form"
          id="redeem-referral-form"
          onSubmit={handleSubmit(async (formData) => {
            try {
              await createReferral({
                workerId: worker.userId,
                data: {
                  referralCodeUsed: formData.referralCode,
                  referralProgram: {
                    id: placementId,
                    name: "PLACEMENT_SIGN_ON_BONUS",
                  },
                },
              });
              logEvent(APP_V2_APP_EVENTS.PLACEMENT_REDEEM_CODE, {
                status: "success",
                placementId,
                referralCode: formData.referralCode,
                name: "PLACEMENT_SIGN_ON_BONUS",
              });
              showSuccessToast(`You have redeemed referral code for this job`);
            } catch (error) {
              const defaultErrorMessage = `There is an error when redeeming the referral code. Please contact support.`;
              if (isAxiosError(error)) {
                const data = error.response?.data as { errors: Array<{ detail: string }> };
                const errorMessage = data?.errors?.[0]?.detail;
                showErrorToast(errorMessage ?? defaultErrorMessage);
              } else {
                showErrorToast(defaultErrorMessage);
              }

              logEvent(APP_V2_APP_EVENTS.PLACEMENT_REDEEM_CODE, {
                status: "error",
                placementId,
                referralCode: formData.referralCode,
                name: "PLACEMENT_SIGN_ON_BONUS",
              });
            }
          })}
        >
          <Controller
            control={control}
            name="referralCode"
            render={({ field, fieldState }) => (
              <>
                <FormLabel sx={{ color: theme.palette.text.secondary }}>
                  Enter referral code
                </FormLabel>
                <Input {...field} placeholder="00000000" size="medium" />
                {fieldState.error?.message ? (
                  <Text variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
                    {fieldState.error.message}
                  </Text>
                ) : undefined}
              </>
            )}
          />
        </Stack>
        <LoadingButton
          variant="contained"
          form="redeem-referral-form"
          type="submit"
          isLoading={isCreateReferralLoading}
          disabled={isCreateReferralLoading}
        >
          Redeem
        </LoadingButton>
      </CardContent>
    </Card>
  );
}
