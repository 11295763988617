import { Card, Illustration } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Box, CardContent, Stack, useTheme } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { Button } from "@src/appV2/redesign/components/Button";
import { useInView } from "react-intersection-observer";

import { type PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { type ReferralStateValue } from "./referralState";

interface IncentiveBannerProps {
  onClick: () => void;
  placementDetails?: PlacementWithDetails;
  title: React.ReactNode;
  description: React.ReactNode;
  ctaText: string;
  referralState: ReferralStateValue;
}

export function IncentiveBanner(props: IncentiveBannerProps) {
  const { onClick, title, description, ctaText, referralState, placementDetails } = props;
  const theme = useTheme();

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0,
    onChange: (inView) => {
      if (!inView) {
        return;
      }

      logEvent(APP_V2_APP_EVENTS.PLACEMENT_INCENTIVE_BANNER_VIEWED, {
        title,
        ctaText,
        referralState,
        placementId: placementDetails?.id,
      });
    },
  });

  return (
    <Card
      ref={ref}
      sx={{
        p: 2,
        background: `linear-gradient(90deg, ${theme.palette.info.dark} 0%, ${theme.palette.info.light}60 100%)`,
        textAlign: "center",
      }}
    >
      <CardContent component={Stack} spacing={7} sx={{ alignItems: "center" }}>
        <Stack spacing={5} sx={{ alignItems: "center" }}>
          <Box
            sx={{
              width: 60,
              height: 60,
              background: theme.palette.background.tertiary,
              borderRadius: theme.borderRadius?.small,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,

              "> svg": {
                height: 52,
                width: 52,
                marginTop: "8px",
                marginLeft: "2px",
              },
            }}
          >
            <Illustration type="pay" />
          </Box>

          <Text semibold variant="h5">
            {title}
          </Text>

          <Text
            variant="body1"
            sx={{ maxWidth: "9  0%", alignSelf: "center", color: theme.palette.text.secondary }}
          >
            {description}
          </Text>
        </Stack>
        <Button
          invert
          variant="contained"
          onClick={() => {
            logEvent(APP_V2_APP_EVENTS.PLACEMENT_INCENTIVE_BANNER_CTA_CLICKED, {
              title,
              ctaText,
              referralState,
              placementId: placementDetails?.id,
            });
            onClick();
          }}
        >
          {ctaText}
        </Button>
      </CardContent>
    </Card>
  );
}
