import { BottomSheet, Card, Illustration } from "@clipboard-health/ui-components";
import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Box, CardContent, DialogContent, Stack, useTheme } from "@mui/material";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";

import { DialogFooter } from "../../components/DialogFooter";
import { LoadingButton } from "../../components/LoadingButton";
import { useCreatePlacementDeepLink } from "../api/useCreatePlacementDeepLink";
import { type PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { useHiringReferralFlag } from "../lib/useHiringReferralFlag";
import { ActionListItem } from "./ActionListItem";
import { ReferralBonusItem } from "./ReferralBonusItem";
import { type ReferralStateValue } from "./referralState";
import { shareReferralLink } from "./shareLink";

interface ReferAFriendBottomSheetProps {
  modalState: UseModalState;
  placementDetails?: PlacementWithDetails;
  referralCode: string;
  referralState: ReferralStateValue;
}

export function ReferAFriendBottomSheet(props: ReferAFriendBottomSheetProps) {
  const { modalState, placementDetails, referralCode, referralState } = props;
  const theme = useTheme();
  const hiringReferralFlag = useHiringReferralFlag();
  const { mutateAsync: createPlacementDeepLink, isLoading: isCreatePlacementDeepLinkLoading } =
    useCreatePlacementDeepLink();

  const totalAmount =
    (hiringReferralFlag.profileActivationBonus.enabled
      ? hiringReferralFlag.profileActivationBonus.referralBonusAmountInUnits
      : 0) +
    (hiringReferralFlag.signOnBonus.enabled
      ? hiringReferralFlag.signOnBonus.referralBonusAmountInUnits
      : 0) +
    15_000;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            isLoading={isCreatePlacementDeepLinkLoading}
            disabled={isCreatePlacementDeepLinkLoading}
            onClick={async () => {
              if (placementDetails) {
                const deepLink = await createPlacementDeepLink({
                  placementId: placementDetails.id,
                  body: { referralCode },
                });

                await shareReferralLink({
                  referralState,
                  referralLink: deepLink.data.attributes.deepLink,
                  placementDetails,
                  bonusAmountInMinorUnits:
                    hiringReferralFlag.signOnBonus.referralBonusAmountInUnits,
                  referralCode,
                });
              } else {
                await shareReferralLink({
                  referralState,
                  referralLink: `https://cbh.care/redeem-code`,
                  bonusAmountInMinorUnits:
                    hiringReferralFlag.signOnBonus.referralBonusAmountInUnits,
                  referralCode,
                });
              }
            }}
          >
            Share your code
          </LoadingButton>
        </DialogFooter>
      }
      onClose={() => {
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_REFER_A_FRIEND_SHEET_ACTION, {
          action: "closed",
          referralState,
          placementId: placementDetails?.id,
        });
        modalState.closeModal();
      }}
    >
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Stack
          flex={1}
          spacing={10}
          sx={{ justifyContent: "center", alignItems: "center", paddingBottom: 6 }}
        >
          <Stack sx={{ alignItems: "center" }}>
            <Illustration type="mail-person" sx={{ alignSelf: "center" }} />
            <Text variant="h2" align="center" sx={{ maxWidth: "90%" }}>
              Invite a friend and you both earn up to{" "}
              {formatCentsAsUsd(totalAmount, {
                maximumFractionDigits: 0,
              })}
            </Text>
          </Stack>
          <Box sx={{ paddingX: 7, width: "100%" }}>
            <Stack
              spacing={7}
              justifyContent="center"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <ActionListItem index={1} label="Send your referral code to a friend" />
              <ActionListItem index={2} label="They sign up" />
              <ActionListItem index={3} label="You both earn rewards" />
            </Stack>
          </Box>
          <Card sx={{ border: `1px solid ${theme.palette.border?.primary}`, width: "100%" }}>
            <CardContent component={Stack} spacing={0}>
              <Stack
                sx={{
                  paddingTop: 2,
                  paddingBottom: 4,
                  borderBottom: `1px solid ${theme.palette.border?.primary}`,
                }}
              >
                <Text semibold variant="h6">
                  You both receive a bonus when your friend:
                </Text>
              </Stack>
              {hiringReferralFlag.profileActivationBonus.enabled ? (
                <ReferralBonusItem
                  iconType="user"
                  label="Completes their hiring profile"
                  amount={hiringReferralFlag.profileActivationBonus.referralBonusAmountInUnits}
                />
              ) : undefined}
              <ReferralBonusItem
                iconType="date-clock"
                label="Works their first Flex shift"
                amount={15_000}
              />
              {hiringReferralFlag.signOnBonus.enabled ? (
                <ReferralBonusItem
                  iconType="briefcase"
                  label="Lands a job and works 5 shifts"
                  amount={hiringReferralFlag.signOnBonus.referralBonusAmountInUnits}
                  sx={{ borderBottom: 0, paddingBottom: 1 }}
                />
              ) : undefined}
            </CardContent>
          </Card>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
