import { CbhIcon, type IconType } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack, type SxProps, type Theme, useTheme } from "@mui/material";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";

interface ReferralBonusItemProps {
  iconType: IconType;
  label: string;
  amount: number;
  sx?: SxProps<Theme> | undefined;
}
export function ReferralBonusItem(props: ReferralBonusItemProps) {
  const { iconType, label, amount, sx } = props;
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.border?.primary}`,
        paddingY: 5,
        ...sx,
      }}
    >
      <Stack direction="row" sx={{ alignItems: "center" }} spacing={4}>
        <CbhIcon type={iconType} size="small" />
        <Text>{label}</Text>
      </Stack>
      <Text>+{formatCentsAsUsd(amount, { maximumFractionDigits: 0 })}</Text>
    </Stack>
  );
}
