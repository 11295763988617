import { BottomSheet, Illustration } from "@clipboard-health/ui-components";
import { ExternalLink, Text, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, DialogContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { useIsShiftDiscoveryEnabled } from "../../ShiftDiscovery/useIsShiftDiscoveryEnabled";
import { type PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { type ReferralDetail } from "../api/useReferrals";
import { useHiringReferralFlag } from "../lib/useHiringReferralFlag";
import { ActionListItem } from "./ActionListItem";
import { type ReferralStateValue } from "./referralState";

interface GetHiredAndBonusBottomSheetProps {
  modalState: UseModalState;
  referral?: ReferralDetail | undefined;
  referralState: ReferralStateValue;
  placementDetails?: PlacementWithDetails;
}

export function GetHiredAndBonusBottomSheet(props: GetHiredAndBonusBottomSheetProps) {
  const { modalState, referral, referralState, placementDetails } = props;
  const history = useHistory();
  const hiringReferralFlag = useHiringReferralFlag();
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  const referralExists = isDefined(referral);

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={() => {
              logEvent(APP_V2_APP_EVENTS.PLACEMENT_GET_HIRED_AND_BONUS_SHEET_ACTION, {
                action: "browse-job",
                referralState,
                placementId: placementDetails?.id,
              });
              history.replace(
                isShiftDiscoveryEnabled
                  ? `/home-v2/placements`
                  : `${DeprecatedGlobalAppV1Paths.OPEN_SHIFTS}?actionType=openJobs`
              );
              modalState.closeModal();
            }}
          >
            Browse jobs
          </Button>
        </DialogFooter>
      }
      onClose={() => {
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_GET_HIRED_AND_BONUS_SHEET_ACTION, {
          action: "closed",
          referralState,
          placementId: placementDetails?.id,
        });
        modalState.closeModal();
      }}
    >
      <DialogContent
        sx={{ paddingTop: 10, paddingBottom: 8, display: "flex", flexDirection: "column" }}
      >
        <Stack flex={1} spacing={8} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Illustration type="pay" />
          <Stack spacing={10} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Text variant="h2" align="center" sx={{ maxWidth: "80%" }}>
              Get hired, get{" "}
              {formatCentsAsUsd(hiringReferralFlag.signOnBonus.bonusAmountInUnits, {
                maximumFractionDigits: 0,
              })}
              !
            </Text>
            <Box sx={{ paddingX: 7, width: "100%" }}>
              <Stack
                spacing={7}
                justifyContent="center"
                alignItems="flex-start"
                sx={{ width: "100%" }}
              >
                <ActionListItem index={1} label="Apply for a job get hired" />
                <ActionListItem index={2} label="Finish orientation and work 5 shifts" />
                <ActionListItem
                  index={3}
                  label={`${
                    referralExists ? "You and referrer receive" : "Receive"
                  } ${formatCentsAsUsd(hiringReferralFlag.signOnBonus.bonusAmountInUnits, {
                    maximumFractionDigits: 0,
                  })} bonus`}
                />
              </Stack>
            </Box>
            <ExternalLink to="https://clipboardhealth.com/terms-of-service">
              Terms & conditions
            </ExternalLink>
          </Stack>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
