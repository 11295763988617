import { isDefined } from "@clipboard-health/util-ts";
import { type Worker } from "@src/appV2/Worker/api/types";
import { parseISO } from "date-fns";

import { type ReferralDetail } from "../api/useReferrals";

const ReferralState = {
  ineligible: "INELIGIBLE",
  inactive: "INACTIVE",
  pending: "PENDING",
  paid: "PAID",
} as const;
type ReferralStateKey = keyof typeof ReferralState;
export type ReferralStateValue = (typeof ReferralState)[ReferralStateKey];

export function getReferralState(
  worker: Worker,
  referrals: ReferralDetail[],
  redeemedCutoffDate: string
): ReferralStateValue {
  if (
    worker.referralCodeUsed &&
    parseISO(worker.referralCodeUsed.createdAt).getTime() < parseISO(redeemedCutoffDate).getTime()
  ) {
    return ReferralState.ineligible;
  }

  if (referrals.length === 0 && !isDefined(worker.referralCodeUsed)) {
    return ReferralState.inactive;
  }

  if (
    referrals.some((referral) => referral.refereeBonus?.transactionId) ||
    isDefined(worker.referralCodeUsed?.amountPaid)
  ) {
    return ReferralState.paid;
  }

  return ReferralState.pending;
}
