import { Share, type ShareOptions } from "@capacitor/share";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";

import { type PlacementDetail } from "../api/useGetPlacement";
import { type ReferralStateValue } from "./referralState";

interface SharePlacementReferralLinkOptions {
  referralLink: string;
  referralState: ReferralStateValue;
  referralCode: string;
  placementDetails?: PlacementDetail;
  bonusAmountInMinorUnits: number;
}

function buildShareContent(options: SharePlacementReferralLinkOptions): ShareOptions {
  const { referralLink, referralCode, placementDetails, bonusAmountInMinorUnits } = options;

  if (placementDetails) {
    const title = `You should check out ${placementDetails.workerTypes?.join(", ")} role at ${
      placementDetails.workplace.name
    }!`;
    return {
      title,
      dialogTitle: title,
      text: `Hey! I'm inviting you to apply to the ${placementDetails.workerTypes?.join(
        ", "
      )} role at ${
        placementDetails.workplace.name
      } on the Clipboard Health App. You can apply, do an interview, and get an offer all within a couple of hours
\nUse my referral code ${referralCode} when you're applying and we'll both earn ${formatCentsAsUsd(
        bonusAmountInMinorUnits,
        { maximumFractionDigits: 0 }
      )} when you get hired. You can view the job here`,
      url: referralLink,
    };
  }

  const title = "Join me on Clipboard Health!";
  return {
    title,
    dialogTitle: title,
    text: `Join me on Clipboard Health where I pick up per-diem shifts and find permanent jobs. You can sign-up, book an interview, and get a job offer all within a couple hours
\nUse my referral code ${referralCode} when you sign up to earn up to ${formatCentsAsUsd(
      bonusAmountInMinorUnits,
      { maximumFractionDigits: 0 }
    )} when you complete your first per-diem shift and get hired at a permanent job. You can download the app here`,
    url: referralLink,
  };
}

export async function shareReferralLink(options: SharePlacementReferralLinkOptions) {
  const { referralLink, referralCode, placementDetails, bonusAmountInMinorUnits, referralState } =
    options;
  const isCapacitorSupported = isCapacitorPlatform();
  const content = buildShareContent(options);

  if (isCapacitorSupported) {
    try {
      const shareResult = await Share.share(content);
      logEvent(APP_V2_APP_EVENTS.HIRING_REFER_A_FRIEND_LINK_SHARED, {
        referralCode,
        link: referralLink,
        isMobile: isCapacitorSupported,
        shareResult,
        placementId: placementDetails?.id,
        bonusAmountInMinorUnits,
        referralState,
      });
    } catch (error) {
      // When user closes the native share dialog manually, it will throw an error
      logEvent(APP_V2_APP_EVENTS.HIRING_REFER_A_FRIEND_LINK_SHARED, {
        referralCode,
        link: referralLink,
        isMobile: isCapacitorSupported,
        error: String(error),
        placementId: placementDetails?.id,
        bonusAmountInMinorUnits,
        referralState,
      });
    }
  } else {
    const mailtoLink = `mailto:?subject=${content.title}&body=${content.text?.replace(
      /\n/g,
      "%0D%0A"
    )} ${content.url}`;
    window.open(mailtoLink, "_blank", "noopener,noreferrer");
    logEvent(APP_V2_APP_EVENTS.HIRING_REFER_A_FRIEND_LINK_SHARED, {
      referralCode,
      link: referralLink,
      isMobile: isCapacitorSupported,
      placementId: placementDetails?.id,
      referralState,
    });
  }
}
