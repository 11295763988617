import { Text } from "@clipboard-health/ui-react";
import { Box, Stack, useTheme } from "@mui/material";

interface ActionListItemProps {
  index: number;
  label: string;
}
export function ActionListItem(props: ActionListItemProps) {
  const { index, label } = props;
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={4} alignItems="center" justifyContent="flex-start">
      <Box
        flexShrink={0}
        sx={{
          margin: "auto",
          backgroundColor: theme.palette.background.invert,
          borderRadius: theme.borderRadius?.xLarge,
          width: 24,
          height: 24,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Text sx={{ color: theme.palette.text.invertPrimary }}>{index}</Text>
      </Box>
      <Text variant="h6" sx={{ color: theme.palette.text.secondary }}>
        {label}
      </Text>
    </Stack>
  );
}
